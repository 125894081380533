import React, { Fragment } from 'react'

const Galeria = () => {
    
    return (
    	<Fragment>
    		<div id="fh5co-gallery">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Nuestra galería</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup image-popup" href="/assets/images/galeria/1.jpg"><img src="/assets/images/galeria/1.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/7.jpg"><img src="/assets/images/galeria/7.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/6.jpg"><img src="/assets/images/galeria/6.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/2.jpg"><img src="/assets/images/galeria/2.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/5.jpg"><img src="/assets/images/galeria/5.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/3.jpg"><img src="/assets/images/galeria/3.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/4.jpg"><img src="/assets/images/galeria/4.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/8.jpg"><img src="/assets/images/galeria/8.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Galeria;