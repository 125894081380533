import axios from 'axios';
import { BUSCAR_NOTAS, DETALLE_NOTA, ULTIMAS_NOTAS, LISTAR_NOTAS, CREAR_NOTA, EDITAR_NOTA, ELIMINAR_NOTA } from './types';
import { setAlert } from './alert';
import { changeLoader } from './loader';


// BUSCAR NOTAS
export const buscarNotas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/listado`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: BUSCAR_NOTAS,
            payload: res.data.notas_blog
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// DETALLE NOTA
export const detalleNota = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA, 'id': id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/detalle`, body, config);

        dispatch({
            type: DETALLE_NOTA,
            payload: res.data.nota
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ULTIMAS NOTAS
export const ultimasNotas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/ultimas`, body, config);

        dispatch({
            type: ULTIMAS_NOTAS,
            payload: res.data.notas_blog
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// LISTADO NOTAS
export const listarNotas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/contenido`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: LISTAR_NOTAS,
            payload: res.data.notas_blog
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// CREAR NOTA
export const crearBlog = (post) => async dispatch => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/crear`, post, config);

        dispatch({
            type: CREAR_NOTA,
            payload: res.data.nota_blog
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// EDITAR NOTA
export const editarBlog = (post) => async dispatch => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/editar`, post, config);

        dispatch({
            type: EDITAR_NOTA,
            payload: res.data.nota_blog
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ELIMINAR NOTA
export const eliminarBlog = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id, 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/blog/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_NOTA,
            payload: {
                id
            }
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}