import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buscarNotas } from '../../Redux/actions/notas_blog';

const styles = {
    img_blog: {
    	objectFit: 'cover',
		maxHeight: '180px'
    }
}

const Blog = () => {

	const dispatch = useDispatch();

	const notas_blog = useSelector(state => state.notas_blog);

	const tableRow = (items) => {
		if(items.length > 0){
        	return (
	        	<div className="row row-bottom-padded-md">
		            {
		            	items.map(item => (
			                <Fragment key={item._id}>
			                	<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="fh5co-blog">
										<a href={`/blog/detalle/${item._id}`}><img className="img-fluid" src={item.imagen_media != null ? item.imagen_media : '/assets/images/default.png'} style={styles.img_blog} alt="" /></a>
										<div className="blog-text">
											<div className="prod-title">
												<h3><a href={`/blog/detalle/${item._id}`}>{item.titulo}</a></h3>
												<span className="posted_date">{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
												<p>{strip_html_tags(item.contenido)}</p>
												<p><a href={`/blog/detalle/${item._id}`}>Ver más...</a></p>
											</div>
										</div> 
									</div>
								</div>
			                </Fragment>
		            	))
		        	}
	        	</div>
        	);
        }else{
        	return (
	        	<div className="row">
	        		<div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
	        			<p>No hay aún momentos por compartir :(</p>
	        		</div>
	        	</div>
        	);
        }
    }

    const strip_html_tags = (str) => {
		if ((str===null) || (str===''))
			return '';
		else
			str = str.toString();
		return str.replace(/<[^>]*>/g, '').substr(0, 70);
	}

	useEffect(() => {
        dispatch(buscarNotas());
    }, [dispatch]);
    
    return (
    	<Fragment>
    		<div id="fh5co-blog-section" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>Algunos momentos</h2>
						</div>
					</div>
				</div>
				<div className="container">
					{
                        tableRow(notas_blog.notas !== undefined ? notas_blog.notas : [])
                    }
				</div>
			</div>
	    </Fragment>
    )
}

export default Blog;