import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { setAlert } from '../../Redux/actions/alert';
import { confirmarInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    contenedor_inicial: {
        marginTop: '20px'
    },
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    },
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    }
}

const Inicio = () => {

	const dispatch = useDispatch();

	const [confirmacionModal, setConfirmacionModal] = useState(false);

	const [confirmarData, setConfirmarData ] = useState({
		id_compra: process.env.REACT_APP_ID_COMPRA,
        codigo: '',
        adultos: [],
        ninos: []
    });

    const onChangeCodigo = (e) => {
        setConfirmarData({
            ...confirmarData, [e.target.name]: e.target.value.trim()
        });
    }

	const handleAbrirConfirmar = async () => {

    	// hacemos el request
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ 'codigo': confirmarData.codigo, 'id_compra': process.env.REACT_APP_ID_COMPRA });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/boletos`, body, config);

			console.log(res.data.invitacion);

            if (res.data.success) {
				setConfirmarData({
					...confirmarData,
				    adultos: res.data.invitacion.adultos,
				    ninos: res.data.invitacion.ninos
				});

                setConfirmacionModal(true);
            } else {
                setAlert('Algo salió mal, inténtalo más tarde', 'danger')
            }
        } catch (error) {
			console.log(error);
            if(error.response){
	            if (error.response.data.errors) {
	                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
	            }else{
	                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	            }
	        }else{
	            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	        }
        }
    }

	const listadoAdultos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12" key={0}>
                    <p><b>Adultos</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-adulto-' + indicador} onChange={e => handleCheckboxAdulto(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-adulto-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const listadoNinos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12 mt-4" key={0}>
                    <p><b>Niños</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-nino-' + indicador} onChange={e => handleCheckboxNino(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-nino-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const handleCheckboxAdulto = ({ target }) => {
    	let adultos_actual = confirmarData.adultos;

    	let posicion_adulto = parseInt(target.id.split('-')[2]) - 1;

		adultos_actual[posicion_adulto].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			adultos: adultos_actual
		});
    }

    const handleCheckboxNino = ({ target }) => {
    	let ninos_actual = confirmarData.ninos;

    	let posicion_nino = parseInt(target.id.split('-')[2]) - 1;

		ninos_actual[posicion_nino].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			ninos: ninos_actual
		});
    }

    const handleGuardarConfirmar = async () => {
        await dispatch(confirmarInvitacion(confirmarData));
        await handleCerrarConfirmar();
    }

	const handleCerrarConfirmar = async () => {
        await setConfirmacionModal(false);
        await setConfirmarData({
        	...confirmarData,
            codigo: '',
            adultos: [],
	        ninos: []
        });
    }
    
    return (
    	<Fragment>

    		{/* Modal de confirmación */}
            <Modal show={confirmacionModal} centered onHide={() => { handleCerrarConfirmar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Selecciona los boletos a confirmar:</p>
                    {
	                    listadoAdultos(confirmarData.adultos)
	                }
	                {
	                    listadoNinos(confirmarData.ninos)
	                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleGuardarConfirmar}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div id="fh5co-couple" className="fh5co-section-gray">
				<div className="container" style={styles.contenedor_inicial}>
					<div className="row row-bottom-padded-md animate-box">
						<div className="col-md-6 offset-md-3 text-center d-flex">
							<div className="col-md-5 col-sm-5 col-5 nopadding">
								<img src="/assets/images/novio.jpg" className="img-fluid" alt="" />
								<h3>Alfonso González</h3>
							</div>
							<div className="col-md-2 col-sm-2 col-2 nopadding"><h2 className="amp-center"><i className="icon-heart"></i></h2></div>
							<div className="col-md-5 col-sm-5 col-5 nopadding">
								<img src="/assets/images/novia.jpg" className="img-fluid" alt="" />
								<h3>Cecilia Leal</h3>
							</div>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2">
							<div className="col-md-12 text-center heading-section">
								<h2>Se van a casar</h2>
								<p><strong>el 17 de Noviembre de 2024 &mdash; Aguascalientes, Ags</strong></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-countdown">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center animate-box">
						<p className="countdown">
							<span id="days"></span>
							<span id="hours"></span>
							<span id="minutes"></span>
							<span id="seconds"></span>
						</p>
					</div>
				</div>
			</div>

			<div id="fh5co-started" className="duidui text-center" data-stellar-background-ratio="0.5" style={ styles.fh5co_cover }>
			<div className="overlay"></div>
				<div className="container">
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>¿Deseas confirmar tu asistencia?</h2>
							<p>Por favor ingresa el código de tu invitación.</p>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-10 offset-md-1">
							<form className="form-inline">
								<div className="col-md-6 col-sm-6">
									<div className="form-group">
										<input type="text" className="form-control" name="codigo" id="codigo" placeholder="Código" onChange={ e => onChangeCodigo(e) } value={confirmarData.codigo || ''} />
									</div>
								</div>
								<div className="col-md-6 col-sm-6">
									<button type="button" className="btn btn-primary btn-block" onClick={() => handleAbrirConfirmar()}>Confirmar</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Inicio;