import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detalleNota, ultimasNotas } from '../../Redux/actions/notas_blog';
import { useParams } from 'react-router-dom';

const styles = {
	encabezado_lateral: {
    	fontSize: '25px'
    },
    titulo_lateral: {
    	fontFamily: 'inherit',
    	fontSize: '15px'
    },
    titulo_central: {
    	fontFamily: 'inherit'
    }
}

const BlogDetalle = () => {

	const { id } = useParams();

	const dispatch = useDispatch();

	const notas_blog = useSelector(state => state.notas_blog);

	const tableRow = (items) => {
		if(items.length > 0){
        	return (
	        	<div className="widget-body">
		            {
		            	items.map(item => (
			                <Fragment key={item._id}>
			                	<div className="latest-post-aside media">
	                                <div className="lpa-left media-body">
	                                    <div className="lpa-title">
	                                        <h5 style={styles.titulo_lateral}><a href={`/blog/detalle/${item._id}`}>{item.titulo}</a></h5>
	                                    </div>
	                                    <div className="lpa-meta">
	                                        <a className="date" href={`/blog/detalle/${item._id}`}>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</a>
	                                    </div>
	                                </div>
	                                <div className="lpa-right">
	                                    <a href={`/blog/detalle/${item._id}`}>
	                                        <img src={item.imagen_mini != null ? item.imagen_mini : '/assets/images/default_mini.png'} title="" alt="" />
	                                    </a>
	                                </div>
	                            </div>
			                </Fragment>
		            	))
		        	}
	        	</div>
        	);
        }else{
        	return (
	        	<div className="widget-body">
        			<p>Aún no tienes momentos por compartir :(</p>
	        	</div>
        	);
        }
    }

	useEffect(() => {
        dispatch(detalleNota(id));

        dispatch(ultimasNotas());
    }, [id, dispatch]);
    
    return (
    	<Fragment>
    		<div id="fh5co-blog-section" className="fh5co-section-gray">
				<div className="container">
					<div className="row align-items-start">
		                <div className="col-lg-8 m-15px-tb">
		                    <article className="article">
		                        <div className="article-img">
		                            <img src={notas_blog.detalle?.imagen != null ? notas_blog.detalle?.imagen : '/assets/images/default.png'} title="" alt="" />
		                        </div>
		                        <div className="article-title">
		                            <h2 style={styles.titulo_central}>{notas_blog.detalle?.titulo}</h2>
		                            <div className="media">
		                                <div className="media-body">
		                                    <label>{new Date(notas_blog.detalle?.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</label>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="article-content">
		                            <p>{notas_blog.detalle?.contenido}</p>
		                        </div>
		                    </article>
		                </div>
		                <div className="col-lg-4 m-15px-tb blog-aside">
		                    <div className="widget widget-latest-post">
		                        <div className="widget-title">
		                            <h3 style={styles.encabezado_lateral}>Últimos post</h3>
		                        </div>
		                        <div className="widget-body">
		                        	{
					                    tableRow(notas_blog.ultimas !== undefined ? notas_blog.ultimas : [])
					                }
		                        </div>
		                    </div>
		                </div>
		            </div>
				</div>
			</div>
	    </Fragment>
    )
}

export default BlogDetalle;