import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="fh5co-header-section" className="sticky-banner">
			<div className="container">
				<div className="nav-header">
					<a href="#" className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></a>
					<a href="/"><img className="logo-header" src="/assets/images/logo-alt.png" alt="" /></a>
					<nav id="fh5co-menu-wrap" role="navigation">
						<ul className="sf-menu" id="fh5co-primary-menu">
							<li className={location.pathname === '/' ? 'active' : ''}><a href="/" >Inicio</a></li>
							<li className={location.pathname === '/detalles' ? 'active' : ''}><a href="/detalles">¿Cuando y donde?</a></li>
							<li className={location.pathname === '/invitados' ? 'active' : ''}><a href="/invitados">Invitados</a></li>
							<li className={location.pathname === '/regalos' ? 'active' : ''}><a href="/regalos">Regalos</a></li>
							<li className={location.pathname === '/galeria' ? 'active' : ''}><a href="/galeria">Galería</a></li>
							<li className={location.pathname === '/blog' || location.pathname.substr(0, 5) === '/blog' ? 'active' : ''}><a href="/blog">Blog</a></li>
							<li className={location.pathname === '/login' ? 'active' : ''}><a href="/login">Login</a></li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
    )
}

export default Header;