import React, { Fragment } from 'react'

const Regalos = () => {
    
    return (
    	<Fragment>
    		<div id="fh5co-when-where" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>Mesa de regalos</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<a className="gallery-img" href="https://mesaderegalos.liverpool.com.mx/" target="_blank" rel="noreferrer"><img src="/assets/images/liverpool.png" className="img-fluid" alt="" /></a>
								</div>
								<div className="cont_codigo">
									<strong>8416167</strong>
								</div>
							</div>
						</div>
						<div className="col-md-4 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<a className="gallery-img" href="https://www.sears.com.mx/Mesa_Regalos" target="_blank" rel="noreferrer"><img src="/assets/images/sears.png" className="img-fluid" alt="" /></a>
								</div>
								<div className="cont_codigo">
									<strong>1437177</strong>
								</div>
							</div>
						</div>
						<div className="col-md-4 text-center animate-box">
							<div className="wedding-events mb-3">
								<div className="gallery animate-box mb-0">
									<a className="gallery-img" href="https://cantia.com.mx/" target="_blank" rel="noreferrer"><img src="/assets/images/cantia.png" className="img-fluid" alt="" /></a>
								</div>
								<div className="cont_codigo">
									<strong>3831784</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Regalos;