import React, { Fragment } from 'react'

const Invitados = () => {
    
    return (
    	<Fragment>
    		<div id="fh5co-guest">
				<div className="container">
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>Padres</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-lg">
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/padre_1.jpg" className="img-fluid" alt="" />
								<h3>Alfonso González</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/madre_1.jpg" className="img-fluid" alt="" />
								<h3>Alejandra Pavia</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/padre_2.jpg" className="img-fluid" alt="" />
								<h3>Fernando Leal</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/madre_2.jpg" className="img-fluid" alt="" />
								<h3>Daniela Ramírez</h3>
							</div>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>Padrinos</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-lg">
						<div className="offset-md-3 col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/madrina.jpg" className="img-fluid" alt="" />
								<h3>Fernanda Rivera</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/padrino.jpg" className="img-fluid" alt="" />
								<h3>Dr. Pablo</h3>
							</div>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>Damas de honor</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/dama_1.jpg" className="img-fluid" alt="" />
								<h3>Lizbeth Vázquez</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/dama_2.jpg" className="img-fluid" alt="" />
								<h3>Paulina Leal</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/dama_3.jpg" className="img-fluid" alt="" />
								<h3>Karen Díaz</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/dama_4.jpg" className="img-fluid" alt="" />
								<h3>Andrea Cantú</h3>
							</div>
						</div>
					</div>
					<div className="row animate-box mt-5">
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2>Caballeros de honor</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-lg">
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/damo_1.jpg" className="img-fluid" alt="" />
								<h3>Oscar Leal</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/damo_2.jpg" className="img-fluid" alt="" />
								<h3>Mauricio Quezada</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/damo_3.jpg" className="img-fluid" alt="" />
								<h3>Yael Romo</h3>
							</div>
						</div>
						<div className="col-md-3 text-center animate-box">
							<div className="groom-men">
								<img src="/assets/images/damo_4.jpg" className="img-fluid" alt="" />
								<h3>Oscar Torres</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Invitados;