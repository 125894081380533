import React, { Fragment, useState } from 'react'

const styles = {
    btn_direccion: {
        cursor: 'pointer'
    }
}

const Detalles = () => {

	const [direcciones, setDirecciones ] = useState({
        ceremonia: 'https://goo.gl/maps/dXoUWM3rsmCWZVay7',
        recepcion: 'https://goo.gl/maps/ot3FDJNmENdZk4MYA'
    });

	const onOpenAddress = (direccion) => {
        window.open(direccion, '_blank');
    }
    
    return (
    	<Fragment>
    		<div id="fh5co-when-where" className="fh5co-section-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2>¿Cuando y donde?</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-md">
						<div className="col-md-6 text-center animate-box">
							<div className="wedding-events">
								<div className="ceremony-bg" style={{ backgroundImage: "url('/assets/images/wed-ceremony.jpg')", cursor: 'pointer' }} onClick={() => onOpenAddress(direcciones.ceremonia)}></div>
								<div className="desc">
									<h3 style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.ceremonia)}>Ceremonia religiosa</h3>
									<p><strong>Sábado 17 de Noviembre de 2024, 7:00 PM</strong></p>
									<p>Esperamos contar con su presencia en la ceremonia religiosa que se llevara a cabo en el templo de San Antonio en punto de las 7:00 pm.</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 text-center animate-box">
							<div className="wedding-events">
								<div className="ceremony-bg" style={{ backgroundImage: "url('/assets/images/wed-party.jpg')", cursor: 'pointer' }} onClick={() => onOpenAddress(direcciones.recepcion)}></div>
								<div className="desc">
									<h3 style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.recepcion)}>Recepción</h3>
									<p><strong>Sabado 17 de Noviembre de 2024, 9:00 PM - 3:00 AM</strong></p>
									<p>Una vez concluida la ceremonia religiosa, nos complacerá que nos acompañe en la recepción, la cual se llevara a cabo en el salón El Mundo de Miguelito a partir de las 9:0pm.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div id="map" className="fh5co-map"></div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Detalles;